import { useState, useEffect } from 'react';
import { onValue, ref } from 'firebase/database';
import { db } from '../firebase';

const useOrders = (statusFilter: boolean) => {
  const [orders, setOrders] = useState([]);


  useEffect(() => {
    const targetShop = localStorage.getItem('targetShop') || '';
    const ordersRef = ref(db, 'orders');

    onValue(ordersRef, (snapshot) => {
     
      const ordersData = snapshot.val() || {};
      let ordersList = Object.keys(ordersData)
        .map(key => ({ id: key, ...ordersData[key] }))
        .filter(order =>{ 
            const archived = order.archived === true;

      
            if (archived !== statusFilter) {
              return false;
            }

            
            if (!statusFilter){

            console.log('----------');
            const today = new Date();
           
            const orderDate = new Date(order.orderable_order_date.split('/').reverse().join('/'));

           
            
            console.log(orderDate);
            console.log(today);
         
            if (today.getDate() === orderDate.getDate() &&
            today.getMonth() === orderDate.getMonth() &&
            today.getFullYear() === orderDate.getFullYear()) {
          // De datums komen overeen, doe hier iets
        } else {
          return false; // De datums komen niet overeen
        }
            console.log('komt er voorbij ');
          }
          
            // Controleer of de order.shop overeenkomt met de targetShop
            if (order.shop !== targetShop) {
              return false;
            }
            return true;
            })  ;
            
        const sortedOrders = ordersList.sort((a, b) => {
            // sort by orderable_order_date
        
            const dateA = a.orderable_order_date ? new Date(a.orderable_order_date.split('/').reverse().join('/')) : null;
            const dateB = b.orderable_order_date ? new Date(b.orderable_order_date.split('/').reverse().join('/')) : null;
          
            if (!dateA && !dateB) {
              return 0;
            }
            if (!dateA) {
              return -1;
            }
            if (!dateB) {
              return 1;
            }
        
            if (dateA < dateB) {
              return -1;
            }
            if (dateA > dateB) {
              return 1;
            }
        
            // sort by orderable_order_time
            const timeA = new Date(`01/01/1970 ${a.orderable_order_time}`).getTime();
            const timeB = new Date(`01/01/1970 ${b.orderable_order_time}`).getTime();
            if (timeA < timeB) {
              return -1;
            }
            if (timeA > timeB) {
              return 1;
            }
        
            return 0;
          });
      setOrders(ordersList);
    });

  
  }, [statusFilter]);


  return  orders ;
};

export default useOrders;