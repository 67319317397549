import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRouterLink, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import { useAuth } from '../auth';
import { auth } from '../firebase';


const LoginPage: React.FC = () => {

  const {loggedIn} =useAuth();
  const[email,setEmail]=useState('');
  const[password,setPassword]=useState('');
  const [status,setStatus]=useState({loading:false,error:false});


  if (loggedIn){
    return <Redirect to="/admin/order"/>;
  }
  
  const handleLogin= async()=>{

    try{
      setStatus({loading:true,error:false});
      console.log(email);
      console.log(password);
      const cred=await signInWithEmailAndPassword(auth, email,password);
   
    }catch(error){
      console.log('error', error);
      setStatus({loading:false,error:true});

    }

 /*   const cred=auth.signInWithEmailAndPassword('test1@example.org','testtest');
    console.log('cred', cred);
   */
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem>
            <IonLabel position='stacked'>
              Email
            </IonLabel>
            <IonInput type="email" value={email} onIonChange={(event)=>{
              setEmail(event.detail.value);
            }}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>
              Password
            </IonLabel>
            <IonInput type="password" value={password} onIonChange={(event)=>{
              setPassword(event.detail.value);
            }}></IonInput>
          </IonItem>
        </IonList>
        {status.error &&
        <IonText color="danger">Invalid creditials</IonText>
        }
      
         
        
            
        <IonButton expand="block" onClick={handleLogin}>Login</IonButton>
        <IonLoading isOpen={status.loading}/>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
