import { IonIcon } from "@ionic/react";
import {   bag, bicycle, flame, radioButtonOn } from "ionicons/icons";

import styled from "styled-components";


const StyledExtra = styled.div`

 /* font-family: 'Londrina Outline', cursive;*/
 font-weight:900;
  font-size: 14px;
  width:80px;
  align-items: center;
  justify-content: center;
  padding:0px;
`;




function OrderItemStatusIcon(props){
  
    return <div className="statusicon" ><div className="itemicon">{getStatusIcon(props.order.status)} </div><div className="itemicon">{getTypeIcon(props.order.orderable_type)}</div></div>

}

function getStatusIcon(status) {
    if (status === "productie") {
      return <IonIcon  icon={flame} color="warning"></IonIcon>
    } else if (status === "processing" || status==='bakklaar') {
      return <IonIcon  icon={radioButtonOn} color="success"></IonIcon>
    } else {
      return null; // Geen icoon voor andere statussen
    }
  }

  function getTypeIcon(status) {
    if (status === "delivery") {
      return <IonIcon   icon={bicycle} ></IonIcon>
    } else {
        return <IonIcon   icon={bag} ></IonIcon>
    }
  }





export default OrderItemStatusIcon;