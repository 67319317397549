/*
const consumerKey = 'ck_4044e2634d3d3954f06f1c23920f9d6d0c1328c7';
const consumerSecret = 'cs_7c58609f4f7ce2827df6b892c8728bf4cffdca9f';
*/
const consumerKey = 'ck_e07ec464cefbf79e6bdfbfe1c3e0063175db5fe8';
const consumerSecret = 'cs_d99f4abf7521a0f05714b64de9f9701635964a9f';

export function updateProduct (productId, bstock){


    const stockStatus = bstock?'instock':'outofstock';
    // Define API request data
    
    const requestData = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      },
      body: JSON.stringify({ stock_status: stockStatus }),
    };
    
    // Send API request using fetch() and basic authentication
    fetch(`https://www.frituurtartaar.be/wp-json/wc/v3/products/${productId}`, requestData)
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));
      

    
}


export function getClosedOrders(){

  
    // Define API request data
    
    const requestData = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      },
      body: JSON.stringify({ status: 'processing' }),
    };
    
    // Send API request using fetch() and basic authentication

      return new Promise((resolve, reject) => {
        fetch(`https://www.frituurtartaar.be/wp-json/custom/v1/orders?status=processing`, requestData)
          .then(response => response.json())
          .then(data => resolve(data))
          .catch(error => reject(error));
      });
}

export function closeDaySummary(){

  
  // Define API request data
  /*
  const requestData = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
    }
  };
  */
  const fetchData = async () => {
    const requestData = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      }
    };
  
    try {
      // Wacht op de fetch aanroep om te voltooien en zet het resultaat om in JSON
      const response = await fetch(`https://www.frituurtartaar.be/wp-json/custom/v1/finish-day`, requestData);
      const data = await response.json();
      console.log(data);
      // Je kunt hier verder gaan met het verwerken van de data
    } catch (error) {
      console.error(error);
      // Verwerk hier de fout
    }
  }; 
  
  // Roep de functie aan
  fetchData();
    
}



export function printDailySummary(){

  
  // Define API request data
  
  const requestData = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
    }
  };
  
  // Send API request using fetch() and basic authentication

  
      fetch(`https://www.frituurtartaar.be/wp-json/custom/v1/print-summary`, requestData)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));
    
}



export function printOrder(orderId){

  
    // Define API request data
    
    const requestData = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      }
    };
    
    // Send API request using fetch() and basic authentication

    
        fetch(`https://www.frituurtartaar.be/wp-json/custom/v1/print-order/${orderId}`, requestData)
          .then(response => response.json())
          .then(data => console.log(data))
          .catch(error => console.error(error));
      
}

export async function getOpenOrders(){

  
    // Define API request data
    
    const requestData = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      },
      
    };
    
    // Send API request using fetch() and basic authentication

      return new Promise((resolve, reject) => {
        fetch(`https://www.frituurtartaar.be/wp-json/custom/v1/orders?status=processing`, requestData)
          .then(response => response.json())
          .then(data => resolve(data))
          .catch(error => reject(error));
      });
}


export async function getProducts(){

  
  // Define API request data
  
  const requestData = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
    },
    
  };
  
  // Send API request using fetch() and basic authentication

    return new Promise((resolve, reject) => {
      fetch(`https://www.frituurtartaar.be/wp-json/custom/v1/categoriesandproducts`, requestData)
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
}


export async function updateOrderToReady(order){


  // Define API request data
  
  const status='klaar';

  if (order.orderable_typeorderable_type=='delivery'){
    status='voor-afhaling';
  }

  
  const requestData = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
    },
    body: JSON.stringify({ status: status }),
  };

  await fetch(`https://www.frituurtartaar.be/wp-json/wc/v3/orders/${order.id}`, requestData)
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error(error));


}

export async function updateOrderToStatus(order, st){

  const requestData = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
    },
    body: JSON.stringify({ status: st }),
  };

  order.status=st;



  // Send API request using fetch() and basic authentication
  await fetch(`https://www.frituurtartaar.be/wp-json/wc/v3/orders/${order.id}`, requestData)
    .then(response => response.json())
    .then(data => console.log('resultaat update status '+data))
    .catch(error => console.error(error));


}


export async function updateOrderToBake(order){
  updateOrderToStatus(order,'productie');

}

export async function updateOrderToSend(order){


    // Define API request data
    



    const requestData = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      },
      body: JSON.stringify({ status: 'onderweg' }),
    };


    
    // Send API request using fetch() and basic authentication
    await fetch(`https://www.frituurtartaar.be/wp-json/wc/v3/orders/${order.id}`, requestData)
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));
      

}