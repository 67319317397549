import React from "react";
import OrderItemAction from "./OrderItemActions";
import OrderItemAddress from "./OrderItemAddress";
import OrderItemExtra from "./OrderItemExtra";
import OrderItemStatusIcon from "./OrderItemStatusIcon";


function OrderListItem({ order }){

    console.log('OrderListItem order prop:', order);

    return <div  className="orderitem">
    
    
    <OrderItemStatusIcon order={order}/><OrderItemAddress order={order}/><OrderItemExtra amount={order.totalamount}/></div>

}

export default OrderListItem;