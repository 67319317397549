import { IonItem, IonLabel, IonToggle } from '@ionic/react';
import { useState } from 'react';
import { updateProduct } from '../functions/FiloWoo';


function ProductToggle(props) {
      const { value, onChange } = props;
  const [toggleValue, setToggleValue] = useState(value || false);


  const itemStyle = {
    display: 'inline-block',
    width: '50%',
    boxSizing: 'border-box',
    padding: '5px'
  };

  const toggleChecked = () => {
    setToggleValue(!toggleValue);
  };

  function handleChange(event) {
    const axios = require('axios');
    const newValue = event.target.checked;
    console.log(event);
  setToggleValue(newValue);
  if (onChange) {

    onChange(newValue);
  }else{
    updateProduct(props.id,newValue);

  }

}
  return (
    <IonItem  onClick={toggleChecked} style={itemStyle} button data-value={props.id} key={props.id}><IonToggle  slot="start" onIonChange={handleChange} checked={toggleValue}></IonToggle>
          <IonLabel slot="start" style={{ textAlign:"left" }}>{props.title}</IonLabel></IonItem>

  );
}


export default ProductToggle;