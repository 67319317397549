const statusTranslations = {
    /*'pre-bakken': 'In behandeling',*/
    bakklaar: 'Klaar om te bakken',
    productie: 'In behandeling',
    klaar: 'Klaar voor ophalen/verzending',
    onderweg: 'Onderweg',
    cancelled: 'Annulatie',
    completed: 'Afgewerkt',
    geleverd: 'Afgeleverd',
    // Voeg hier meer statussen en hun vertalingen toe
  };
  
  export function translateStatus(status) {
    return statusTranslations[status] || status; // Geef de originele status terug als er geen vertaling is
  }