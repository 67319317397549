import { IonButton, IonButtons, IonIcon,  IonToolbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import OrderCategory from "../component/OrderCategory";
import OrderHeader from "../component/OrderHeader";
import { print as printicon,  flame, close, checkmarkCircle, bicycle, home } from 'ionicons/icons';
import { printOrder,   updateOrderToStatus } from "../functions/FiloWoo";
import { translateStatus } from "../functions/Maintenance";




function OrderDetail(props){

  const Status = {
    COMPLETED:'completed',
    READY: 'klaar',
    PREBAKKEN: 'pre-bakken',
    BAKKEN: 'productie',
    VERZONDEN: 'onderweg',
  };


  const [orderData, setOrderData] = useState( {});

  
  useEffect(() => {
  
    setOrderData(props.order || {});
  }, [props.order]);
  


  const [loading,setLoading]=useState(false);


  function print(){
    printOrder(orderData.id);
   
  }


  function formatDate(timestamp) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return new Date(timestamp).toLocaleString('be-NL', options).replace(',', '');
  }




  async function cancelAction(){



  
    const confirmed = window.confirm('Weet u zeker dat u deze actie wilt voltooien?');

   
    if (confirmed) {
      // Code voor de actie bij bevestiging
      // Voer hier de gewenste actie uit
      console.log('Actie voltooien');
      changeStatus('cancelled');
    } else {
      // Code voor de actie bij annulering
      // Voer hier de gewenste actie uit
      console.log('Actie geannuleerd');
    }
   
 
  }
  




  async function changeStatus(status){
    setLoading(true);




      updateOrderToStatus(orderData, status);
   // props.update();


   

   setLoading(false);
   //loading=true;

  }






    return (
<div>
      {Object.keys(orderData).length === 0  ? (
        <p>Selecteer een bestelling</p>
      ) : (



        <div>
        {loading===true &&
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      }
        <IonToolbar className="actionstoolbar">


        <IonButtons slot="start">
          <IonButton onClick={()=>cancelAction()} fill="solid" color="danger">
            Annuleer
            <IonIcon slot="start" icon={close}></IonIcon>
          </IonButton>
        </IonButtons>

        <IonButtons className="subactions" slot="end">
        <IonButton onClick={print} fill="outline" style={{ marginRight: '20px' }}>
            <IonIcon slot="start" icon={printicon}></IonIcon>
            Print
          </IonButton>
          </IonButtons>

          {orderData.archived ? (
            
            <IonButtons className="subactions" slot="end">
            {orderData.orderable_type === "delivery"? (
            <IonButton onClick={()=>changeStatus(Status.VERZONDEN)} fill="outline" disabled={orderData.status === Status.VERZONDEN} >
            <IonIcon slot="start" icon={bicycle} color="primary"></IonIcon>
            Verzonden
          </IonButton>
          ):''}
          <IonButton onClick={()=>changeStatus(Status.COMPLETED)}  fill="outline" disabled={orderData.status === Status.COMPLETED} >
            <IonIcon slot="start" icon={home} ></IonIcon>
            Afgewerkt
          </IonButton>
          </IonButtons>
          ):(
            <IonButtons className="subactions" slot="end">
            <IonButton onClick={()=>changeStatus(Status.PREBAKKEN)} fill="outline" disabled={orderData.status === Status.BAKKEN} >
            <IonIcon slot="start" icon={flame} color="warning"></IonIcon>
            Bakken
          </IonButton>
          <IonButton onClick={()=>changeStatus(Status.READY)}  fill="outline" disabled={orderData.status === Status.READY} >
            <IonIcon slot="start" icon={checkmarkCircle} color="success"></IonIcon>
            Gebakken
          </IonButton>
          {orderData.orderable_type === "delivery"? (
        <IonButton onClick={()=>changeStatus(Status.VERZONDEN)} fill="outline" disabled={orderData.status === Status.VERZONDEN} >
            <IonIcon slot="start" icon={bicycle} color="primary"></IonIcon>
            Verzonden
          </IonButton>
          ):''}
          <IonButton onClick={()=>changeStatus(Status.COMPLETED)}  fill="outline"  >
            <IonIcon slot="start" icon={home} ></IonIcon>
            Afgewerkt
          </IonButton>
          </IonButtons>
          )}

  
      
      </IonToolbar>

      <OrderHeader order={orderData}/>
      <div className="order-detail-container">
      <div className="order-info">
          <h2>Bestelgegevens</h2>
          <p>Status:<strong> {translateStatus(orderData.status)}</strong></p>
          <p>Bestelnummer:<strong> {orderData.id}</strong></p>

          <p>Naam klant:<strong> {orderData?.billing?.first_name ?? 'Onbekend'} {orderData?.billing?.last_name ?? 'Onbekend'}</strong> </p>
          </div>

          <div className="order-status-changes">
          <h2>Statuswijzigingen</h2>
          <ul>
          {orderData.statusChanges 
          ? Object.values(orderData.statusChanges).map((change, index) => (
              <li key={index}>
                {formatDate(change.timestamp)}: {translateStatus(change.newStatus)}
              </li>
            ))
          : <p>Geen statuswijzigingen gevonden</p>
        }
          </ul>
        </div>
</div>
         
          {orderData.frietamount=== 0 ? (
            <div className="catTitle">Geen frieten</div>
        
      ) : (
        <OrderCategory key="frieten" title="Frieten" amount={orderData.frietamount} items={orderData.frieten}/>
        )}


        
        {orderData.sauzenamount === 0  ? (
          null
      ) : (
        <OrderCategory key="sauzen" title="Sauzen"  amount={orderData.sauzenamount}  items={orderData.sauzen}/>
        )}
        {orderData.andereamount === 0 ? (
          null
      ) : (
        <OrderCategory key="snacks" title="Snacks"  amount={orderData.andereamount} items={orderData.snacks}/>
        )}
        {orderData.drankamount === 0 ? (
        null
      ) : (
        <OrderCategory key="frisdranken" title="Dranken"  amount={orderData.drankamount}  items={orderData.frisdranken}/>
        )}




        </div>
      )}



    </div>

    );
      
      

}

export default OrderDetail;