// components/OrderPageLayout.tsx
import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonList, IonPage, IonSearchbar, IonSplitPane, IonTitle, IonToolbar } from '@ionic/react';
import OrderDetail from './OrderDetail.js';
import OrderListItem from '../component/OrderListItem.jsx';
import { closeCircleOutline } from 'ionicons/icons';

const OrderPageLayout = ({ orders, pageLabel }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [filter, setFilter] = useState('');
  const targetShop = localStorage.getItem('targetShop') || '';

  const getItemData = (id) => {
    const order = orders.find((o) => o.id === id);

    setSelectedOrder(order);
  };

  useEffect(() => {
    console.log('use effect orderpagelayout');
    if (selectedOrder) {
      // Zoek naar de bijgewerkte order met dezelfde ID als de geselecteerde order
      const updatedOrder = orders.find(order => order.id === selectedOrder.id);
  
      // Update de geselecteerde order als de bijgewerkte order gevonden is
      if (updatedOrder) {
        setSelectedOrder(updatedOrder);
      } else {
        // Als de order niet meer bestaat in de lijst, deselecteer de order
        setSelectedOrder(null);
      }
    }
  }, [orders, selectedOrder]);

  const handleButtonClick = async (buttonNumber) => {
    setActiveButton(buttonNumber);
    // Stuur de status naar de server of andere logica...
  };

  if (!Array.isArray(orders)) {
    // Log de error en toon eventueel een foutmelding op het scherm
    console.error('Error: orders is not an array', orders);
    // Je kunt hier ook een foutmelding in de UI weergeven
    return <div>Er is een fout opgetreden bij het laden van de orders.</div>;
  }

  const handleFilterChange = (e) => {
    setFilter(e.detail.value || '');

  };

  const clearFilter = () => {
    setFilter('');
   // setSelectedOrder(null);
  };

  const filteredOrders = filter
  ? orders.filter(order => {
      // Combineer de gewenste velden in een zoekbare string.
      const fullNameForward = (order.billing?.firstname + ' ' + order.billing?.lastname).toLowerCase();
      const fullNameReverse = (order.billing?.lastname + ' ' + order.billing?.firstname).toLowerCase();
      const address = order.billing?.address_1?.toLowerCase() || '';
      const email = order.billing?.email?.toLowerCase() || '';
      const idString = order.id.toString();

      // Controleer of een van de velden de zoekterm bevat.
      return idString.includes(filter) ||
             fullNameForward.includes(filter.toLowerCase()) ||
             fullNameReverse.includes(filter.toLowerCase()) ||
             address.includes(filter.toLowerCase()) ||
             email.includes(filter.toLowerCase());
    })
  : orders;

 

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{targetShop ? `Bestellingen - Frituur ${targetShop}` : 'Bestellingen'}</IonTitle>

        
        <IonButtons slot="end">
        <IonButton  className={activeButton === 1 ? 'active' : ''}>
          <img src="assets/icon/kalm.png" alt="kalm" onClick={() => handleButtonClick(1)}  width={"25px"}/>
       
   
          </IonButton>

          <IonButton  className={activeButton === 2 ? 'active' : ''}>
          <img src="assets/icon/druk.png" alt="druk" onClick={() => handleButtonClick(2)}  width={"25px"}/>
       
   
          </IonButton>

          <IonButton className={activeButton === 3 ? 'active' : ''} >
          <img src="assets/icon/redalert.png" alt="redalert" onClick={() => handleButtonClick(3)} width={"25px"}/>
       
   
          </IonButton>


        </IonButtons>
      
     
        </IonToolbar>
      </IonHeader>
      <IonHeader>

      </IonHeader>
      <IonContent className="ion-padding" >


        <IonSplitPane when="sm" contentId="main" >


         
        <IonList style={{overflow:"auto"}} >
        <IonSearchbar
              value={filter}
              onIonChange={handleFilterChange}
              placeholder="Zoek op id, naam, adres, ..."
            />
          
        {filteredOrders.map(order => (
              <IonItem key={order.id} 
              button onClick={() => getItemData(order.id)}  
              className={selectedOrder && selectedOrder.id === order.id ? "selected" : ""}>
                <OrderListItem order={order} 
                
                />
              </IonItem>
            ))}
          
        </IonList>
          
      <div className="ion-page" id="main">

        <IonContent className="ion-padding">
        {selectedOrder ? (        <OrderDetail order={selectedOrder} />)
        : (
          <p>Selecteer een order om de details weer te geven.</p>
        )}
       
         
        
        </IonContent>
      </div>
    </IonSplitPane>


   


      </IonContent>
    </IonPage>
  );
};

export default OrderPageLayout;