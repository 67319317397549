import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonRouterLink, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { lockClosed, print as printicon } from 'ionicons/icons';
import { closeDaySummary, printDailySummary } from '../functions/FiloWoo';

const Settings: React.FC = () => {

  const [selectedOption, setSelectedOption] = useState(''); // Beginwaarde instellen

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log('verandering selection');
    localStorage.setItem('targetShop', event.target.value);
  };

  useEffect(() => {
    // Probeer de opgeslagen waarde uit localStorage te halen
    const savedOption = localStorage.getItem('targetShop');

    // Als er een opgeslagen waarde is, stel deze in als de huidige waarde
    if (savedOption) {
      setSelectedOption(savedOption);
    }
  }, []); 

  function print(){
    printDailySummary();
   
  }

  async function closeDay(){
    await closeDaySummary();
    console.log('wachten');
    await new Promise(resolve => setTimeout(resolve, 10000));

    printDailySummary();
   
  }



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
      <IonItem style={{ width: '350px' }}>
      <IonLabel position="stacked" >Kies de locatie: </IonLabel>
      <IonSelect  value={selectedOption} onIonChange={handleOptionChange}>
  <IonSelectOption  value="Tartaar">Frituur tartaar</IonSelectOption>
  <IonSelectOption  value="Extra">Frituur andalouse</IonSelectOption>
  
</IonSelect>
</IonItem>

          <br />
<IonButton onClick={print}   fill="outline" className="ion-margin-top" >
            <IonIcon slot="start" icon={printicon}></IonIcon>
            Print DagOverzicht
          </IonButton>
          <br/>
          <IonButton onClick={closeDay}   color="danger" fill="outline" className="ion-margin-top">
            <IonIcon slot="start" icon={lockClosed}></IonIcon>
            Dag afsluiten en overzicht afdrukken
          </IonButton>
      </IonContent>

    </IonPage>
  );
};

export default Settings;
