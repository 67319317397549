import { IonIcon } from "@ionic/react";
import { atCircle, bag, bicycle, circle, cogOutline, flame, radioButtonOn } from "ionicons/icons";
import React, { useState } from "react";
import CountdownTimer from "./CountdownTimer";
import { translateStatus } from "../functions/Maintenance";

function OrderItemAddress(props){
    console.log(props);
    return <div className="adres"><div className="idname">{props.order.id} {props.order.shipping.first_name} {props.order.shipping.last_name}
    </div>
    <div className="adresline">{props.order.shipping.address_1} {props.order.shipping.address_2}</div> <div className="adresline">{props.order.shipping.postcode} {props.order.shipping.city}</div>
    <div className="date">{props.order.orderable_order_date}</div>  <div className="date"></div>
    {props.order.archived ? (
        <div className="status"> {translateStatus(props.order.status)}</div>
    ):(
      


        <CountdownTimer orderDate={props.order.orderable_order_date} 
    orderTime={props.order.orderable_order_time} />
    )}
    </div>

}








export default OrderItemAddress;