import React from 'react';
import useOrders from '../hooks/useOrders';
import OrderPageLayout from './OrderPageLayout'; // Dit is je huidige OrderPage.tsx zonder de logica voor het ophalen van orders

const ActiveOrderPage: React.FC = () => {
  const orders = useOrders(false);
  
  return (
    <OrderPageLayout orders={orders} pageLabel="ActiveOrders" />
  );
};

export default ActiveOrderPage;