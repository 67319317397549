import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ orderDate, orderTime }) => {
  // Initial state for remaining time and style
  const [timeLeft, setTimeLeft] = useState('');
  const [timerStyle, setTimerStyle] = useState('normalStyle');

  useEffect(() => {

    const getFullDateTime = (date, time) => {
        const dateParts = date.split('/').map(Number);
        const timeParts = time.split(':').map(Number);
        // Controleer op geldigheid van de datum en tijd
        if (dateParts.length === 3 && timeParts.length === 2) {
          const [day, month, year] = dateParts;
          const [hours, minutes] = timeParts;
          const fullDate = new Date(year, month - 1, day, hours, minutes, 0);
          // Controleer of het Date object geldig is
          if (!isNaN(fullDate.getTime())) {
            return fullDate;
          }
        }
        return null;
      };


    // Function to update the countdown
    const updateCountdown = () => {
        const orderDateTime = getFullDateTime(orderDate, orderTime);

        if (!orderDateTime){
            setTimeLeft(orderTime);
            setTimerStyle('normalStyle');
            return;
        }
        const currentTime = new Date();
        let  timeDifference = orderDateTime - currentTime;

        let overtime = false;
        if (timeDifference < 0) {
          overtime = true;
          timeDifference = Math.abs(timeDifference); // Neem de absolute waarde voor over tijd
        }
  

        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);
  
        // Update time left met uren, minuten en seconden
        const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        setTimeLeft(overtime ? `${orderTime} Over tijd: ${formattedTime}` : formattedTime);
  
        // Update style based on time left
        if (overtime) {
          setTimerStyle('timeUpStyle');
        } else if (hours === 0 && minutes < 10 ) {
          setTimerStyle('oneMinuteStyle');
        } else {
          setTimerStyle('normalStyle');
        }
      };

    // Set interval to update every second
    const intervalId = setInterval(updateCountdown, 1000);

    // Clear interval on cleanup
    return () => clearInterval(intervalId);
  }, [orderDate, orderTime ]);

  return (
    <div className={timerStyle}>
      {timeLeft}
    </div>
  );
};

export default CountdownTimer;