import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from "firebase/auth";

import 'firebase/auth';
import 'firebase/database';
/*
const firebaseConfig = {
    apiKey: "AIzaSyAA2tH0JlyjiY0eB33E00RK9ON8slmO-DQ",
    authDomain: "flash-chat-fc630.firebaseapp.com",
    databaseURL: "https://flash-chat-fc630.firebaseio.com",
    projectId: "flash-chat-fc630",
    storageBucket: "flash-chat-fc630.appspot.com",
    messagingSenderId: "309104578614",
    appId: "1:309104578614:web:6f3d7bd9734b021ff8c897",
    measurementId: "G-LVKLPMQV8M"
  };
  */
  const firebaseConfig = {
    apiKey: "AIzaSyAEj_yeiaBo0VU0RpRH8AAmypOPpD25LmA",
    authDomain: "frituur-tartaar-database.firebaseapp.com",
    databaseURL: "https://frituur-tartaar-database-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "frituur-tartaar-database",
    storageBucket: "frituur-tartaar-database.appspot.com",
    messagingSenderId: "704173312892",
    appId: "1:704173312892:web:d133180c63aac768b272f9",
    measurementId: "G-VL7VBDCKNZ"
  };

const app=  initializeApp(firebaseConfig);

export const db = getDatabase(app);

export const auth=getAuth(app);
