import React from 'react';
import useOrders from '../hooks/useOrders';
import OrderPageLayout from './OrderPageLayout'; // Hergebruik dezelfde layout component

const ArchivedOrderPage: React.FC = () => {
  const orders = useOrders(true);
  
  return (
    <OrderPageLayout orders={orders.reverse()} pageLabel="ArchivedOrders" />
  );
};

export default ArchivedOrderPage;