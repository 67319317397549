import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";




import {cart, cube,settings }  from 'ionicons/icons'

import Settings from "./pages/Settings";
import { useAuth } from "./auth";

import Product from "./pages/Product";

import ActiveOrderPage from "./pages/ActiveOrderPage";
import ArchivedOrderPage from "./pages/ArchivedOrderPage";




const AppTabs: React.FC = () => {



  const {loggedIn} =useAuth();
  if (!loggedIn){

    return <Redirect to="/login"/>;
  }


  return (

        <IonTabs>
          <IonRouterOutlet>

            <Route exact path="/admin/order">
              <ActiveOrderPage />
            </Route>
            <Route exact path="/admin/archiveorder">
              <ArchivedOrderPage />
            </Route>

            <Route exact path="/admin/product">
              <Product />
            </Route>


            <Route exact path="/admin/settings">
              <Settings />
            </Route>
            <Redirect exact path="/" to="/admin/order" />
          </IonRouterOutlet>

          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/admin/order"><IonIcon icon={cart}></IonIcon><IonLabel>Bestellingen</IonLabel></IonTabButton>
            
            <IonTabButton tab="archive" href="/admin/archiveorder"><IonIcon icon={cart}></IonIcon><IonLabel>Archief Bestellingen</IonLabel></IonTabButton>
            <IonTabButton tab="product" href="/admin/product"><IonIcon icon={cube}></IonIcon><IonLabel>Producten</IonLabel></IonTabButton>
            <IonTabButton tab="settings" href="/admin/settings"><IonIcon icon={settings}>
              </IonIcon><IonLabel>Instellingen</IonLabel></IonTabButton>

          </IonTabBar>
        </IonTabs>
  
  );
};

export default AppTabs;
