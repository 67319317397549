import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";


function OrderHeader(props){

 

  const [orderData, setOrderData] = useState( {});
  
  useEffect(() => {
  
    setOrderData(props.order || {});
  });

    return (
        

     <IonGrid>
        <IonRow>
        <IonCol> Id: <strong> {orderData?.id}</strong></IonCol>
        <IonCol>Type:<strong>  {orderData?.shipping_method}</strong></IonCol>
     
      </IonRow>
      <IonRow>
      <IonCol>Telefoon:<strong> {orderData?.billing?.phone}</strong></IonCol>
      <IonCol>Email: <strong>{orderData?.billing?.email}</strong></IonCol>
     
      </IonRow>
      <IonRow>
      <IonCol>Betaalmethode:<strong> {orderData?.payment_method_title}</strong></IonCol>
      <IonCol>Gewenste: <strong>{orderData?.orderable_order_date}  {orderData?.orderable_order_time}</strong> </IonCol>
     
      </IonRow>


    </IonGrid>

    );
      
      

}

export default OrderHeader;