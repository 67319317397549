import React, { useState } from "react";


function OrderCategory(props){

 
  const itemData = props.items || {};





    return (



        
        <div>

        
        <div className="catTitle">{props.title} x {props.amount}</div>
{        itemData.map((friet, index)=> { 
            console.log(friet);
            if (friet.name){
                return <div key={index}>{friet.amount} x {friet.name} 
                {friet.meta && Object.keys(friet.meta).length > 0 ? (
          <div style={{ marginLeft: "1em", marginTop: "0.1em", marginBottom: "0.5em" }}>
            {Object.values(friet.meta).map((property, propertyIndex) => (
              <div key={propertyIndex} >
   
                <p style={{ margin: "0", fontSize: "0.9em" }}>
                {property.display_key}: <span dangerouslySetInnerHTML={{ __html: property.value }}></span></p>
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
                
                </div>
            }else{
                return null;
            }
    
             
              }    )}




        </div>
   
   
   
   
   
   );
      
      

}

export default OrderCategory;