import {  IonContent, IonHeader,  IonList, IonPage,  IonTitle,  IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import ProductToggle from '../component/ProductToggle.jsx';
import WooCommerceApi from '../functions/WooCommerceApi.js';

const consumerKey = 'ck_e07ec464cefbf79e6bdfbfe1c3e0063175db5fe8';
const consumerSecret = 'cs_d99f4abf7521a0f05714b64de9f9701635964a9f';

const Product: React.FC = () => {

  const [products,setProducts]=useState([]);


  useEffect(()=>{
    console.log("use effect");

    const requestData = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      },
      
    };
    
    // Send API request using fetch() and basic authentication

    const fetchData = async () => {

      try {
      const response = await fetch(`https://www.frituurtartaar.be/wp-json/custom/v1/categoriesandproducts`, requestData);
      const result = await response.json();
 
      setProducts(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    
        };
        fetchData();

   }
  
   ,[]);

   
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Producten</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
      <IonList style={{overflow:"auto"}} className="ion-padding">
          {products.map((cat)=>
          <div key={cat.id}><h2 key={cat.id}>{cat.name}</h2>
         {   cat.products.map((prod)=><ProductToggle key={prod.id} id={prod.id} title={prod.title} value={prod.status==='instock'?true:false}/>)}
          </div>
          )}
        </IonList>


      </IonContent>
    </IonPage>
  );
};

export default Product;
