import {
  IonApp,
  IonLoading,
  IonRouterOutlet,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";


import LoginPage from "./pages/LoginPage";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import AppTabs from "./AppTabs";
import { AuthContext } from "./auth";

import './theme/custom.css';

const App: React.FC = () => {


  const[authState,setAuthState]=useState({loading:true,loggedIn:false});
  useEffect(()=>{
    auth.onAuthStateChanged((user)=>{
      console.log("authstatechanged "+user);
      setAuthState({loading:false,loggedIn:Boolean(user)});
        });
  },[])


  if (authState.loading){
    return <IonLoading isOpen/>
  }

  return (
    <IonApp>
      <AuthContext.Provider value={{loggedIn:authState.loggedIn}}>
      <IonReactRouter>
   
          <IonRouterOutlet>
            <Route exact path="/login">
              {authState.loggedIn}?<Redirect to="/admin/order"/>:
              <LoginPage />
              
          
            </Route>
              <Route path="/admin">
                <AppTabs />

              </Route>  
            <Redirect exact path="/" to="/login" />
          </IonRouterOutlet>


      
      </IonReactRouter>
      </AuthContext.Provider> 
    </IonApp>
  );
};

export default App;
