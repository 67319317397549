
import styled from "styled-components";

const StyledExtra = styled.div`

 /* font-family: 'Londrina Outline', cursive;*/
 font-weight:900;
  font-size: 32px;
  width:80px;
  align-items: center;
  justify-content: center;
  padding:15px;
`;



function OrderItemExtra(props){

    return <div className="orderamount">{props.amount}</div>

}

export default OrderItemExtra;